import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { injectReducer } from 'store'
import reducer from './store'
import { useDispatch, useSelector } from 'react-redux'
import { getData, setTableData } from './store/dataSlice'
import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi'
import Tooltip from 'components/Ui/Tooltip'
import { ConfirmationModal, Table } from 'components/Ui'
import { deleteApiRecord } from 'services/CompanyService'
import { Success } from 'utils/alerts'
injectReducer('company', reducer)

export default function Company() {
    const dispatch = useDispatch()
    const { data, tableData } = useSelector(state => state.company.data)

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
        tableData.sort,
        tableData.query,
        tableData.pageIndex,
        tableData.pageSize,
        tableData.selectedTab,
        tableData.checkBox,
    ])

    const fetchData = () => dispatch(getData({ ...tableData }))

    const handleDelete = id => {
        deleteApiRecord(id)
            .then(() => {
                Success('Deleted Successfully!')
                fetchData()
            })
            .catch(error => console.log('Error: ', error))
    }

    const columns = useMemo(
        () => [
            {
                field: 'name',
                header: 'Name',
                className: 'min-w-[150px]',
                orderable: true,
                // renderCell: row => <NameColumn row={row} />,
            },
            {
                field: 'company_email',
                header: 'Email',
                className: 'min-w-[150px]',
            },
            {
                field: 'phone',
                header: 'Contact No.',
                className: 'min-w-[150px]',
            },
            {
                field: 'city',
                header: 'city',
                className: 'min-w-[150px]',
                orderable: true,
            },
            {
                field: 'address',
                header: 'Address',
                className: 'min-w-[150px]',
            },
            {
                field: 'pin',
                header: 'Pin',
                className: 'min-w-[150px]',
            },
            // {
            //     field: 'is_temporary',
            //     header: 'Temporary',
            //     className: 'min-w-[150px]',
            //     renderCell: row => row.is_temporary ? 'Yes' : 'No',
            // },
            // {
            //     header: 'Status',
            //     renderCell: row => {
            //         return (
            //             <ConfirmationModal
            //                 onConfirm={() => {
            //                     handleStatus(row.id, { is_active: !row.is_active })
            //                 }}
            //             >
            //                 <div>
            //                     <div
            //                         className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${row.is_active ? 'text-success bg-success' : 'text-danger bg-danger'}`}
            //                     >
            //                         {row.is_active ? 'Active' : 'Inactive'}
            //                     </div>
            //                 </div>
            //             </ConfirmationModal>
            //         )
            //     },
            // },
            {
                header: 'Action',
                className: 'px-4',
                renderCell: row => {
                    return (
                        <div className="flex items-center gap-3">
                            <Tooltip title={'Edit'}>
                                <Link to={`/company/${row.id}/edit`} className="hover:text-primary">
                                    <HiOutlinePencil size={20} />
                                </Link>
                            </Tooltip>
                            <Tooltip title={'Delete'}>
                                <ConfirmationModal
                                    message={'Are you sure you want to delete this record!'}
                                    onConfirm={() => {
                                        handleDelete(row.id)
                                    }}
                                >
                                    <span className="hover:text-danger">
                                        <HiOutlineTrash size={20} />
                                    </span>
                                </ConfirmationModal>
                            </Tooltip>
                        </div>
                    )
                },
            },
        ],
        // eslint-disable-next-line
        [tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab, tableData.checkBox]
    )

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Companies</h2>
            </div>
            <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                <Table
                    filter={false}
                    checkboxFilter={true}
                    datas={data}
                    columns={columns}
                    add_new={'/company/create'}
                    pagingData={tableData}
                    onChange={e => dispatch(setTableData(e))}
                />
            </div>
        </>
    )
}
