import { BrowserRouter } from 'react-router-dom'
import 'assets/css/app.css'
import store, { persistor } from 'store'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import Router from 'route'

export default function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <ToastContainer />
                    <Router />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    )
}
