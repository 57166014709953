import React from 'react'
import { Navigate, Outlet, useRoutes } from 'react-router-dom'
import { AppLayout, LoginLayout } from 'components/layout'
import Home from 'views/home'
import Profile from 'views/auth/Profile'
import Login from 'views/auth/Login'
import Error from 'views/Error'
import Company from 'views/company'
import CreateComapny from 'views/company/create'
import User from 'views/user'
import CreateUser from 'views/user/create'
import Categories from 'views/product/categories'
import CreateCategories from 'views/product/categories/create'
import Products from 'views/product/products'
import CreateProducts from 'views/product/products/create'
import UnassignedProducts from 'views/product/unassignedProducts'
import Journey from 'views/product/journey'
import ProductComponent from 'views/product/productComponent'
import Serials from 'views/product/serials'
import CreateSerials from 'views/product/serials/create'
import Faqs from 'views/product/faqs'

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: <AppLayout />,
            children: [
                //dashboard
                { path: '/', element: <Home /> },

                //profile
                { path: '/profile', element: <Profile /> },
                { path: '/user/all-users/:id/profile/', element: <Profile /> },

                //company
                {
                    path: '/company',
                    element: <Outlet />,
                    children: [
                        { path: '', element: <Company /> },
                        { path: 'create', element: <CreateComapny /> },
                        { path: ':id/edit', element: <CreateComapny /> },
                    ],
                },

                //user
                {
                    path: '/user',
                    element: <Outlet />,
                    children: [
                        { path: '', element: <User /> },
                        { path: 'create', element: <CreateUser /> },
                        { path: ':id/edit', element: <CreateUser /> },
                    ],
                },

                //categories
                {
                    path: '/manage-product/categories',
                    element: <Outlet />,
                    children: [
                        { path: '', element: <Categories /> },
                        { path: 'create', element: <CreateCategories /> },
                        { path: ':id/edit', element: <CreateCategories /> },
                    ],
                },

                //products
                {
                    path: '/manage-product/products',
                    element: <Outlet />,
                    children: [
                        { path: '', element: <Products /> },
                        { path: 'create', element: <CreateProducts /> },
                        { path: ':id/edit', element: <CreateProducts /> },
                    ],
                },

                //unassigned products
                {
                    path: '/manage-product/unassigned-products',
                    element: <Outlet />,
                    children: [{ path: '', element: <UnassignedProducts /> }],
                },

                //journey
                {
                    path: '/manage-product/products/journey/:productId',
                    element: <Outlet />,
                    children: [{ path: '', element: <Journey /> }],
                },

                //components
                {
                    path: '/manage-product/products/components/:productId',
                    element: <Outlet />,
                    children: [{ path: '', element: <ProductComponent /> }],
                },

                //serials
                {
                    path: '/manage-product/products/serials/:productId',
                    element: <Outlet />,
                    children: [
                        { path: '', element: <Serials /> },
                        { path: 'create', element: <CreateSerials /> },
                        { path: ':id/edit', element: <CreateSerials /> },
                    ],
                },

                //faqs
                {
                    path: '/manage-product/products/faqs/:productId',
                    element: <Outlet />,
                    children: [{ path: '', element: <Faqs /> }],
                },
            ],
        },
        {
            path: '/',
            element: <LoginLayout />,
            children: [{ path: '/login', element: <Login /> }],
        },
        { path: 'error', element: <Error /> },
        { path: '*', element: <Navigate to="/error" replace /> },
    ])
}
