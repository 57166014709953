import { forwardRef, useRef } from 'react'

export default forwardRef(function DatePicker(
    {
        type = 'date', // time, date, timedatetime-local, week, month
        id = '',
        label = '',
        placeholder = '',
        className = '',
        error = '',
        ...props
    },
    ref
) {
    const input = useRef()

    return (
        <div className={className}>
            {label && (
                <label htmlFor={id} className={`block mb-1 font-medium ${error ? 'text-danger' : 'text-black dark:text-white'}`}>
                    {label}
                </label>
            )}
            <div className="relative flex items-stretch flex-nowrap">
                <input
                    {...props}
                    type={type}
                    id={id}
                    placeholder={label ? label : placeholder}
                    className={`custom-input-date w-full flex-auto rounded-lg border bg-transparent py-2 pl-3 ${error ? 'border-danger focus:ring-danger focus:border-danger' : 'border-stroke focus:ring-primary focus:border-primary dark:border-form dark:bg-input dark:focus:ring-secondary dark:focus:border-secondary'} outline-none disabled:cursor-not-allowed disabled:bg-whiter dark:disabled:bg-black`}
                    ref={ref || input}
                />
            </div>
            {error && <p className="text-sm text-danger">{error}</p>}
        </div>
    )
})
