import ApiService from './ApiService'

export const apiLogIn = data => ApiService.post('/userauth/login', data)
export const apiLogOut = () => ApiService.post('/userauth/logout/')
export const apiChangePassword = data => ApiService.put('/userauth/change-password/', data)
export const apiForgotPassword = data => ApiService.post('userauth/forgot-password/', data)
export const apiResetPassword = data => ApiService.post('/userauth/reset-password/', data)
export const getApiUser = () => ApiService.get(`/userauth/userprofiles/`)
export const getApiProfile = id => ApiService.get(`/userauth/userprofiles/${id}/`)
export const updateApiProfile = (id, data) => ApiService.put(`/userauth/userprofiles/${id}/`, data)
