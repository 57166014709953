import React, { useEffect, useMemo, useState } from 'react'
import { injectReducer } from 'store'
import reducer from './store'
import { useDispatch, useSelector } from 'react-redux'
import { getData, setTableData } from './store/dataSlice'
import Tooltip from 'components/Ui/Tooltip'
import { ConfirmationModal, Modal, Table } from 'components/Ui'
import { Button, TextInput } from 'components/Form'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Success } from 'utils/alerts'
import { useParams } from 'react-router-dom'
import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi'
import { createApiRecord, deleteApiRecord, updateApiRecord } from 'services/JourneyService'
injectReducer('journey', reducer)

const validationSchema = Yup.object().shape({
    description: Yup.string().required('description is required'),
    text: Yup.string().required('key is required'),
})

const initialData = {
    description: '',
    text: '',
}

export default function Journey() {
    const dispatch = useDispatch()
    const { productId } = useParams()
    const { data, tableData } = useSelector(state => state.journey.data)
    const [record, setRecord] = useState({ ...initialData })
    const [open, setOpen] = useState(false)

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab])

    const fetchData = () => dispatch(getData({ ...tableData, product_id: productId }))

    const handleDelete = id => {
        deleteApiRecord(id)
            .then(() => {
                Success('Deleted Successfully!')
                fetchData()
            })
            .catch(error => console.log('Error: ', error))
    }

    const handleSubmit = datas => {
        const data = {
            ...datas,
            product_id: productId,
        }
        if (data.id) {
            updateApiRecord(data.id, data)
                .then(() => {
                    Success('Updated Successfully!')
                    fetchData()
                    setOpen(false)
                })
                .catch(error => console.log('Error: ', error))
        } else {
            createApiRecord(data)
                .then(() => {
                    Success('Created Successfully!')
                    fetchData()
                    setOpen(false)
                })
                .catch(error => console.log('Error: ', error))
        }
    }

    const columns = useMemo(
        () => [
            {
                field: 'text',
                header: 'Text',
                className: 'min-w-[150px]',
            },
            {
                field: 'description',
                header: 'Description',
                className: 'min-w-[150px]',
            },
            // {
            //     field: 'product_label',
            //     header: 'Product Name',
            //     className: 'min-w-[150px]',
            // },
            // {
            //     field: 'total_price',
            //     header: 'Total Price',
            //     className: `min-w-[150px]`,
            //     bg: 'total_price_confidence'
            // },
            // {
            //     field: 'invoice_number',
            //     header: 'Invoice Number',
            //     className: 'min-w-[150px]',
            //     bg: 'invoice_confidence'
            // },
            // {
            //     field: 'product_serial_number',
            //     header: 'Serial Number',
            //     className: 'min-w-[150px]',
            // },
            {
                header: 'Action',
                className: 'px-4',
                renderCell: row => {
                    return (
                        <div className="flex items-center gap-3">
                            <Tooltip title={'Assign Company'}>
                                <HiOutlinePencil
                                    size={20}
                                    onClick={() => {
                                        setOpen(true)
                                        setRecord(row)
                                    }}
                                    className="hover:text-primary"
                                />
                            </Tooltip>
                            <Tooltip title={'Delete'}>
                                <ConfirmationModal
                                    message={'Are you sure you want to delete this record!'}
                                    onConfirm={() => {
                                        handleDelete(row.id)
                                    }}
                                >
                                    <span className="hover:text-danger">
                                        <HiOutlineTrash size={20} />
                                    </span>
                                </ConfirmationModal>
                            </Tooltip>
                        </div>
                    )
                },
            },
        ],
        // eslint-disable-next-line
        [tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab]
    )

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Journey</h2>
            </div>
            <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                <Table
                    buttons={['Add New']}
                    onClick={() => {
                        setOpen(true)
                        setRecord(initialData)
                    }}
                    filter={false}
                    search_show={false}
                    datas={data}
                    columns={columns}
                    pagingData={tableData}
                    onChange={e => dispatch(setTableData(e))}
                />
            </div>
            <Modal
                show={open}
                onClose={() => {
                    setOpen(false)
                }}
            >
                <div className="flex flex-col gap-1 m-3 sm:flex-row sm:items-center sm:justify-between">
                    <h2 className="font-semibold text-black text-title-md dark:text-white">
                        {record?.id ? 'Update Journey' : 'Add New Journey'}
                    </h2>
                </div>
                <div className="flex flex-col items-center">
                    <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="flex flex-col gap-3 p-6">
                            <Formik
                                initialValues={record}
                                validationSchema={validationSchema}
                                onSubmit={values => {
                                    handleSubmit(values)
                                }}
                            >
                                {({ values, touched, errors }) => (
                                    <Form>
                                        <div className="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <Field name="text">
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type="text"
                                                        className="md:col-span-2"
                                                        label="Key"
                                                        name="text"
                                                        error={errors.text && touched.text}
                                                        message={errors.text}
                                                        value={values?.text}
                                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="description">
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type="text"
                                                        className="md:col-span-2"
                                                        label="Description"
                                                        name="description"
                                                        error={errors.description && touched.description}
                                                        message={errors.description}
                                                        value={values?.description}
                                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div className="flex items-center justify-end gap-4 mt-3">
                                            <Button
                                                className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                                                onClick={() => setOpen(false)}
                                                type="button"
                                            >
                                                Discard
                                            </Button>
                                            <Button
                                                type="submit"
                                                className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
