import ApiService from './ApiService'

export const getApiData = data => {
    var datas
    if (data) {
        datas = {
            page: data.pageIndex,
            page_size: data.pageSize,
        }
        data.sort?.map(sort => {
            if (!sort.dir) return sort
            datas.ordering = sort.dir === 'desc' ? `-${sort.column}` : `${sort.column}`
            return sort
        })
        if (data.query) {
            datas.search = data.query
        }
        if (data.selectedTab) {
            datas.status = data.selectedTab
        }
    }
    return ApiService.get('/user/get_users', datas)
}
export const apiUnitCount = () => ApiService.get('/user/get_user_products_count')
export const apiProductCount = () => ApiService.get('/user/get_products_count')
export const apiUserCount = () => ApiService.get('/user/get_users_count')
export const apiProductsByMonth = () => ApiService.get('/user/get_products_count_by_month')
export const apiProductsDemography = () => ApiService.get('/user/get_products_demography')
export const apiProductCategoryCount = () => ApiService.get('/user/get_products_category_count')
export const apiUserACtivity = () => ApiService.get('/user/user_product')
