import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import SidebarLinkGroup from './SidebarLinkGroup'
import menuItems from './MenuItems'

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
    const location = useLocation()
    const { pathname } = location

    const trigger = useRef(null)
    const sidebar = useRef(null)

    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded')
    const [sidebarExpanded, setSidebarExpanded] = useState(
        storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
    )

    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!sidebar.current || !trigger.current) return
            if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return
            setSidebarOpen(false)
        }
        document.addEventListener('click', clickHandler)
        return () => document.removeEventListener('click', clickHandler)
    })

    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!sidebarOpen || keyCode !== 27) return
            setSidebarOpen(false)
        }
        document.addEventListener('keydown', keyHandler)
        return () => document.removeEventListener('keydown', keyHandler)
    })

    useEffect(() => {
        localStorage.setItem('sidebar-expanded', sidebarExpanded.toString())
        if (sidebarExpanded) {
            document.querySelector('body')?.classList.add('sidebar-expanded')
        } else {
            document.querySelector('body')?.classList.remove('sidebar-expanded')
        }
    }, [sidebarExpanded])

    return (
        <aside
            ref={sidebar}
            className={`absolute left-0 top-0 z-10 flex h-screen w-65 flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
        >
            <div className="flex items-center justify-between gap-2 px-6 py-2 lg:py-3">
                <NavLink to="/" className="flex items-center">
                    <img className="w-12 h-12" src="/images/ypp-logo.png" alt="Logo" />
                    <h2 className="text-2xl font-bold text-white">YPP</h2>
                </NavLink>

                <button
                    ref={trigger}
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                    aria-controls="sidebar"
                    aria-expanded={sidebarOpen}
                    className="block lg:hidden"
                >
                    <svg
                        className="fill-current"
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
                            fill=""
                        />
                    </svg>
                </button>
            </div>

            <div className="flex flex-col overflow-y-auto duration-300 ease-linear no-scrollbar">
                <nav className="px-3 py-3">
                    <div>
                        <h3 className="mb-3 ml-3 text-sm font-semibold text-bodydark2">MENU</h3>
                        <ul className="flex flex-col gap-1 mb-3">
                            {menuItems.map(link => {
                                if (link?.children) {
                                    return (
                                        <SidebarLinkGroup activeCondition={pathname.includes(link.module)} key={link?.id}>
                                            {(handleClick, open) => {
                                                return (
                                                    <React.Fragment>
                                                        <NavLink
                                                            to="#"
                                                            className={`group relative flex items-center gap-2 py-2 px-3 pl-4 font-medium text-sm 
                            text-bodydark1 duration-300 ease-linear before:absolute before:left-0 before:w-1 
                            before:duration-300 before:ease-linear hover:before:bg-secondary hover:bg-graydark hover:text-white hover:before:h-full before:!h-full  
                            ${pathname.includes(link?.module) && 'before:bg-secondary bg-graydark dark:bg-meta4 text-white'}`}
                                                            onClick={e => {
                                                                e.preventDefault()
                                                                sidebarExpanded ? handleClick() : setSidebarExpanded(true)
                                                            }}
                                                        >
                                                            {link?.icon} {link?.label}
                                                            <svg
                                                                className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
                                                                    open && 'rotate-180'
                                                                }`}
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 20 20"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
                                                                    fill=""
                                                                />
                                                            </svg>
                                                        </NavLink>
                                                        <div
                                                            className={`translate transform overflow-hidden ${!open && 'hidden'}`}
                                                        >
                                                            <ul className="flex flex-col gap-1 pl-6 mt-1">
                                                                {link?.children?.map(item => (
                                                                    <li key={item.id}>
                                                                        <NavLink
                                                                            to={item?.link}
                                                                            className={({ isActive }) =>
                                                                                `group relative flex items-center gap-2 py-2 px-3 pl-4 font-medium text-sm 
                                      text-bodydark1 duration-300 ease-linear before:absolute before:left-0 before:w-1 
                                      before:duration-300 before:ease-linear hover:before:bg-secondary hover:bg-graydark hover:text-white hover:before:h-full before:!h-full 
                                      ${isActive && 'before:bg-secondary bg-graydark dark:bg-meta4 text-white'}`
                                                                            }
                                                                        >
                                                                            {item?.label}
                                                                        </NavLink>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            }}
                                        </SidebarLinkGroup>
                                    )
                                } else {
                                    return (
                                        <li key={link.id}>
                                            <NavLink
                                                to={link.link}
                                                className={`group relative flex items-center gap-2 py-2 px-3 pl-4 font-medium text-sm 
                      text-bodydark1 duration-300 ease-linear before:absolute before:left-0 before:w-1 
                      before:duration-300 before:ease-linear hover:before:bg-secondary hover:bg-graydark hover:text-white hover:before:h-full before:!h-full 
                      ${(pathname === '/' ? link.module === 'dashboard' : pathname.includes(link.module)) && 'before:bg-secondary bg-graydark dark:bg-meta4 text-white'}`}
                                            >
                                                {link.icon} {link.label}
                                            </NavLink>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>
                </nav>
            </div>
        </aside>
    )
}

export default Sidebar
