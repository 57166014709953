import { Button, TextInput } from 'components/Form'
import { HiOutlineUser } from 'react-icons/hi'
import { Link, useNavigate } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import useAuth from 'utils/hooks/useAuth'
import { Success } from 'utils/alerts'

const validationSchema = Yup.object().shape({
    email: Yup.string().required('email is required'),
    password: Yup.string().required('Password is required'),
})

export default function Login() {
    const navigate = useNavigate()
    const { signIn } = useAuth()

    const onSignIn = async (values, setSubmitting) => {
        const { email, password } = values
        setSubmitting(true)
        const result = await signIn({ email, password })

        if (result.status !== 'failed') {
            Success('Login successfully!')
            navigate('/')
        }
        setSubmitting(false)
    }

    return (
        <>
            <h2 className="mb-3 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">Login to YPP</h2>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    onSignIn(values, setSubmitting)
                }}
            >
                {({ touched, errors, isSubmitting }) => (
                    <Form className="flex flex-col gap-3">
                        <Field name="email">
                            {({ field, form }) => (
                                <TextInput
                                    type="text"
                                    id="email"
                                    name="email"
                                    label={'email'}
                                    autoComplete="email"
                                    isFocused={true}
                                    icon={<HiOutlineUser />}
                                    error={errors.email && touched.email}
                                    message={errors.email}
                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                />
                            )}
                        </Field>
                        <Field name="password">
                            {({ field, form }) => (
                                <TextInput
                                    id="password"
                                    name="password"
                                    label={'Password'}
                                    autoComplete="password"
                                    password={true}
                                    error={errors.password && touched.password}
                                    message={errors.password}
                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                />
                            )}
                        </Field>
                        {/* <Checkbox
                            id="remember"
                            name="remember"
                            className="block"
                            checked={data}
                            label={'remember'}
                            onChange={e => setData(e.target.checked)}
                        /> */}
                        <Button
                            className="w-full mt-3 text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                            disabled={isSubmitting}
                            type="submit"
                        >
                            Sign In
                        </Button>
                        <div className="text-center">
                            <p className="font-medium">
                                <Link to="/login">Forgot your password?</Link>
                            </p>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}
