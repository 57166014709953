import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, FileUpload, Select, TextInput } from 'components/Form'
import { createApiRecord, updateApiRecord } from 'services/CategoryService'
import { useDispatch, useSelector } from 'react-redux'
import { getRecord } from './store/dataSlice'
import reducer from './store'
import { injectReducer } from 'store'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Success } from 'utils/alerts'
import { yesNoOptions } from 'utils/options'
import { getCategoryData } from 'store/data/dataSlice'
injectReducer('category', reducer)

const validationSchema = Yup.object().shape({
    name: Yup.string().required('category name is required'),
    sub_name: Yup.string().required('sub name is required'),
    is_subcategory: Yup.boolean().required('this field is required'),
})

const Create = props => {
    const { initialData } = props
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { record, loading } = useSelector(state => state.category.data)

    useEffect(() => {
        if (id) {
            fetchData(id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const fetchData = id => dispatch(getRecord(id))

    const handleDiscard = () => navigate('/manage-product/categories')

    const handleSubmit = datas => {
        const data = { ...datas }
        if (id) {
            if (typeof data.image === 'string') {
                delete data.image
            }
            updateApiRecord(id, data)
                .then(() => {
                    Success('Updated Successfully!')
                    handleDiscard()
                    dispatch(getCategoryData())
                })
                .catch(error => console.log('Error: ', error))
        } else {
            createApiRecord(data)
                .then(() => {
                    Success('Created Successfully!')
                    handleDiscard()
                    dispatch(getCategoryData())
                })
                .catch(error => console.log('Error: ', error))
        }
    }

    if (loading && id) {
        return null
    }

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Categories</h2>
            </div>
            <div className="flex flex-col items-center">
                <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                    <div className="px-5 py-3 border-b border-stroke dark:border-strokedark">
                        <h3 className="font-medium text-black dark:text-white">{id ? 'Update Category' : 'Add New Category'}</h3>
                    </div>
                    <div className="flex flex-col gap-3 p-6">
                        <Formik
                            initialValues={id ? record : initialData}
                            validationSchema={validationSchema}
                            onSubmit={values => {
                                handleSubmit(values)
                            }}
                        >
                            {({ values, touched, errors }) => (
                                <Form>
                                    <div className="grid grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-4">
                                        <Field name="name">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Category Name"
                                                    name="name"
                                                    error={errors.name && touched.name}
                                                    message={errors.name}
                                                    value={values?.name}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="sub_name">
                                            {({ field, form }) => (
                                                <TextInput
                                                    type="text"
                                                    className="md:col-span-2"
                                                    label="Description"
                                                    name="sub_name"
                                                    error={errors.sub_name && touched.sub_name}
                                                    message={errors.sub_name}
                                                    value={values?.sub_name}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="is_subcategory">
                                            {({ field, form }) => (
                                                <Select
                                                    options={yesNoOptions}
                                                    label="Is Subcategory"
                                                    size="sm"
                                                    placeholder="Select"
                                                    className="md:col-span-2"
                                                    name="is_subcategory"
                                                    error={errors.is_subcategory && touched.is_subcategory}
                                                    message={errors.is_subcategory}
                                                    value={values?.is_subcategory}
                                                    onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                />
                                            )}
                                        </Field>
                                        <Field name="image">
                                            {({ field, form }) => (
                                                <FileUpload
                                                    label="Image"
                                                    className="md:col-span-2"
                                                    name="image"
                                                    onChange={e => {
                                                        form.setFieldValue(field.name, e.target.files[0])
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div className="flex items-center justify-end gap-4 mt-3">
                                        <Button
                                            className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                                            onClick={handleDiscard}
                                        >
                                            Discard
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

Create.defaultProps = {
    initialData: {
        name: '',
        sub_name: '',
        image: '',
        is_subcategory: '',
    },
}

export default Create
