import ApiService from './ApiService'

export const getApiData = data => {
    var datas
    if (data) {
        datas = {
            page: data.pageIndex,
            page_size: data.pageSize,
            product: data?.product_id,
        }
        data.sort?.map(sort => {
            if (!sort.dir) return sort
            datas.ordering = sort.dir === 'desc' ? `-${sort.column}` : `${sort.column}`
            return sort
        })
        if (data.query) {
            datas.search = data.query
        }
        if (data.selectedTab) {
            datas.status = data.selectedTab
        }
    }
    return ApiService.get('/product/product_assembly/', datas)
}
export const createApiRecord = data => ApiService.post('/product/product_assembly/', data)
export const getApiRecord = id => ApiService.get(`/product/product_assembly/${id}/`)
export const updateApiRecord = (id, data) => ApiService.put(`/product/product_assembly/${id}/`, data)
export const deleteApiRecord = id => ApiService.delete(`/product/product_assembly/${id}/`)
