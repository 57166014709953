import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getApiData, getApiRecord } from 'services/SerialService'
import { getApiRecord as getApiProductRecord } from 'services/ProductService'

export const getData = createAsyncThunk('serial/data/getData', async data => {
    const response = await getApiData(data)
    return response.data
})

export const getRecord = createAsyncThunk('serial/data/getRecord', async id => {
    const response = await getApiRecord(id)
    const data = {
        ...response.data,
        has_customer: 1,
        user_email: response.data?.user?.email,
    }
    return data
})

export const getProductRecord = createAsyncThunk('serial/data/getProductRecord', async id => {
    const response = await getApiProductRecord(id)
    const data = {
        product_label: response.data?.name,
        warranty_period: response.data?.warranty_period,
        batch_number: response.data?.batch_number,
    }
    return data
})

const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    selectedTab: '',
    sort: [{ column: '', dir: '' }],
    company: '',
}

const dataSlice = createSlice({
    name: 'serial/data',
    initialState: {
        loading: true,
        data: [],
        tableData: initialTableData,
    },
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload
        },
        setTableData: (state, { payload }) => {
            state.tableData = payload
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getData.pending, state => {
                state.loading = true
                state.tableData.total = 0
            })
            .addCase(getData.fulfilled, (state, { payload }) => {
                state.loading = false
                state.data = payload.results
                state.tableData.total = payload.count
            })
            .addCase(getData.rejected, state => {
                state.loading = false
                state.data = []
            })
            .addCase(getRecord.fulfilled, (state, { payload }) => {
                state.loading = false
                state.record = payload
            })
            .addCase(getRecord.pending, state => {
                state.loading = true
                state.record = null
            })
            .addCase(getRecord.rejected, state => {
                state.loading = false
                state.record = null
            })
            .addCase(getProductRecord.fulfilled, (state, { payload }) => {
                state.loading = false
                state.productRecord = payload
            })
            .addCase(getProductRecord.pending, state => {
                state.loading = true
                state.productRecord = null
            })
            .addCase(getProductRecord.rejected, state => {
                state.loading = false
                state.productRecord = null
            })
    },
})

export const { setData, setTableData } = dataSlice.actions

export default dataSlice.reducer
