import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { HiOutlineUser } from 'react-icons/hi'

import UserOne from '../../../assets/images/user-02.png'
import { TbLogout2 } from 'react-icons/tb'
import useAuth from 'utils/hooks/useAuth'
import { useSelector } from 'react-redux'

const DropdownUser = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const { signOut } = useAuth()
    const user = useSelector(state => state.auth.user)

    const trigger = useRef(null)
    const dropdown = useRef(null)

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdown.current) return
            if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return
            setDropdownOpen(false)
        }
        document.addEventListener('click', clickHandler)
        return () => document.removeEventListener('click', clickHandler)
    })

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return
            setDropdownOpen(false)
        }
        document.addEventListener('keydown', keyHandler)
        return () => document.removeEventListener('keydown', keyHandler)
    })

    return (
        <div className="relative inline-block text-left">
            <Link ref={trigger} onClick={() => setDropdownOpen(!dropdownOpen)} className="flex items-center gap-1" to="#">
                <span className="hidden text-right lg:block">
                    <span className="block text-sm font-medium text-black dark:text-white">
                        {user.first_name} {user.last_name}
                    </span>
                    <span className="block text-xs">{user.email}</span>
                </span>

                <span className="w-10 h-10 pl-1 rounded-full">
                    <img src={UserOne} alt="User" />
                </span>

                <svg
                    stroke="currentColor"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    className="hidden fill-current sm:block"
                    height="25"
                    width="25"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path>
                </svg>
            </Link>

            {/* <!-- Dropdown Start --> */}
            <div
                ref={dropdown}
                onFocus={() => setDropdownOpen(true)}
                onBlur={() => setDropdownOpen(false)}
                className={`absolute right-0 mt-2 flex flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark origin-top-right sm:right-0 w-60 ${
                    dropdownOpen === true ? 'block' : 'hidden'
                }`}
            >
                <ul className="flex flex-col">
                    <li>
                        <Link
                            to="/"
                            className="flex items-center gap-3 px-4 py-3 text-sm font-medium duration-300 ease-in-out lg:text-base hover:bg-gray2 dark:hover:bg-strokedark"
                        >
                            <HiOutlineUser size={23} />
                            My Profile
                        </Link>
                    </li>
                </ul>
                <button
                    onClick={() => signOut()}
                    className="flex items-center gap-3 px-4 py-3 text-sm font-medium duration-300 ease-in-out border-t lg:text-base hover:bg-gray2 dark:hover:bg-strokedark border-stroke dark:border-strokedark"
                >
                    <TbLogout2 size={23} />
                    Log Out
                </button>
            </div>
            {/* <!-- Dropdown End --> */}
        </div>
    )
}

export default DropdownUser
