import React from 'react'
import { Outlet } from 'react-router-dom'

export default function LoginLayout() {
    return (
        <div className="flex flex-col items-center min-h-screen p-10 bg-gray dark:bg-boxdark2 dark:text-bodydark">
            <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="flex flex-wrap items-center">
                    <div className="hidden w-full xl:block xl:w-1/2">
                        <div className="px-10 text-center py-7">
                            {/* <Logo className="dark:hidden" type="dark" />
                                <Logo
                                    className="hidden dark:block"
                                    type="logo"
                                /> */}
                            <h2 className="text-2xl font-bold text-black">YPP</h2>
                            <p className="2xl:px-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit suspendisse.</p>
                            <span className="flex justify-center mt-5">
                                <img className="w-[80%] h-[80%]" src="/images/ypp-logo.png" alt="error" />
                            </span>
                        </div>
                    </div>

                    <div className="w-full max-w-xl border-stroke dark:border-strokedark xl:w-1/2 xl:border-l-2">
                        <div className="w-full p-5 sm:p-6 xl:p-10">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
