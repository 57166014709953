import React, { useEffect, useMemo, useState } from 'react'
import { injectReducer } from 'store'
import reducer from './store'
import { useDispatch, useSelector } from 'react-redux'
import { getData, setTableData } from './store/dataSlice'
// import Tooltip from 'components/Ui/Tooltip'
import { Modal, Table } from 'components/Ui'
import { Button, Select, TextInput } from 'components/Form'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Success } from 'utils/alerts'
import { useParams } from 'react-router-dom'
// import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi'
import { createApiRecord, updateApiRecord } from 'services/ProductComponentService'
injectReducer('components', reducer)

const validationSchema = Yup.object().shape({
    component_id: Yup.number().required('please select is required'),
    quantity: Yup.number().required('key is required'),
})

const initialData = {
    component_id: '',
    quantity: '',
}

export default function ProductComponents() {
    const dispatch = useDispatch()
    const { productId } = useParams()
    const { componentData } = useSelector(state => state.listData)
    const { data, tableData } = useSelector(state => state.components.data)
    const [record, setRecord] = useState({ ...initialData })
    const [open, setOpen] = useState(false)

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab])

    const fetchData = () => dispatch(getData({ ...tableData, product_id: productId }))

    // const handleDelete = (id) => {
    //     deleteApiRecord(id)
    //         .then(() => {
    //             Success('Deleted Successfully!')
    //             fetchData()
    //         })
    //         .catch(error => console.log('Error: ', error))
    // }

    const handleSubmit = datas => {
        const data = {
            ...datas,
            product_id: productId,
        }
        if (data.id) {
            updateApiRecord(data.id, data)
                .then(() => {
                    Success('Updated Successfully!')
                    fetchData()
                    setOpen(false)
                })
                .catch(error => console.log('Error: ', error))
        } else {
            createApiRecord(data)
                .then(() => {
                    Success('Created Successfully!')
                    fetchData()
                    setOpen(false)
                })
                .catch(error => console.log('Error: ', error))
        }
    }

    const columns = useMemo(
        () => [
            {
                field: 'product',
                header: 'Product',
                className: 'min-w-[150px]',
                renderCell: row => row?.product?.name,
            },
            {
                field: 'component',
                header: 'Component',
                className: 'min-w-[150px]',
                renderCell: row => row?.component?.name,
            },
            {
                field: 'quantity',
                header: 'Quantity',
                className: 'min-w-[150px]',
            },
            // {
            //     header: 'Action',
            //     className: 'px-4',
            //     renderCell: row => {
            //         return (
            //             <div className="flex items-center gap-3">
            //                 <Tooltip title={'Assign Company'}>
            //                     <HiOutlinePencil
            //                         size={20}
            //                         onClick={() => {
            //                             setOpen(true);
            //                             setRecord({ ...row, component_id: row?.component?.id });
            //                         }}
            //                         className="hover:text-primary"
            //                     />
            //                 </Tooltip>
            //                 <Tooltip title={'Delete'}>
            //                     <ConfirmationModal
            //                         message={'Are you sure you want to delete this record!'}
            //                         onConfirm={() => {
            //                             handleDelete(row.id)
            //                         }}
            //                     >
            //                         <span className="hover:text-danger">
            //                             <HiOutlineTrash size={20} />
            //                         </span>
            //                     </ConfirmationModal>
            //                 </Tooltip>
            //             </div>
            //         )
            //     },
            // },
        ],
        // eslint-disable-next-line
        [tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab]
    )

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Components</h2>
            </div>
            <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                <Table
                    buttons={['Add New']}
                    onClick={() => {
                        setOpen(true)
                        setRecord(initialData)
                    }}
                    filter={false}
                    search_show={false}
                    datas={data}
                    columns={columns}
                    pagingData={tableData}
                    onChange={e => dispatch(setTableData(e))}
                />
            </div>
            <Modal
                show={open}
                onClose={() => {
                    setOpen(false)
                }}
            >
                <div className="flex flex-col gap-1 m-3 sm:flex-row sm:items-center sm:justify-between">
                    <h2 className="font-semibold text-black text-title-md dark:text-white">
                        {record?.id ? 'Update Component' : 'Add New Component'}
                    </h2>
                </div>
                <div className="flex flex-col items-center">
                    <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="flex flex-col gap-3 p-6">
                            <Formik
                                initialValues={record}
                                validationSchema={validationSchema}
                                onSubmit={values => {
                                    handleSubmit(values)
                                }}
                            >
                                {({ values, touched, errors }) => (
                                    <Form>
                                        <div className="grid grid-cols-1 gap-x-4 gap-y-4">
                                            <Field name="component_id">
                                                {({ field, form }) => (
                                                    <Select
                                                        options={componentData}
                                                        label="Component"
                                                        size="sm"
                                                        placeholder="Select"
                                                        className="md:col-span-2"
                                                        name="component_id"
                                                        error={errors.component_id && touched.component_id}
                                                        message={errors.component_id}
                                                        value={values?.component_id}
                                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="quantity">
                                                {({ field, form }) => (
                                                    <TextInput
                                                        type="text"
                                                        className="md:col-span-2"
                                                        label="Quantity"
                                                        name="quantity"
                                                        error={errors.quantity && touched.quantity}
                                                        message={errors.quantity}
                                                        value={values?.quantity}
                                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                        <div className="flex items-center justify-end gap-4 mt-3">
                                            <Button
                                                className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                                                onClick={() => setOpen(false)}
                                                type="button"
                                            >
                                                Discard
                                            </Button>
                                            <Button
                                                type="submit"
                                                className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
