import ApiService from './ApiService'

export const getApiData = data => {
    var datas
    if (data) {
        datas = {
            page: data.pageIndex,
            page_size: data.pageSize,
            is_component: data.components,
        }
        data.sort?.map(sort => {
            if (!sort.dir) return sort
            datas.ordering = sort.dir === 'desc' ? `-${sort.column}` : `${sort.column}`
            return sort
        })
        if (data.query) {
            datas.search = data.query
        }
        if (data.selectedTab) {
            datas.status = data.selectedTab
        }
        if (data.company) {
            datas.company = data.company
        }
        if (data.companyType) {
            datas.company_type = data.companyType
        }
    }
    return ApiService.get('/product/product/', datas)
}
export const getUnassignedApiData = data => {
    var datas
    if (data) {
        datas = {
            page: data.pageIndex,
            page_size: data.pageSize,
        }
        data.sort?.map(sort => {
            if (!sort.dir) return sort
            datas.ordering = sort.dir === 'desc' ? `-${sort.column}` : `${sort.column}`
            return sort
        })
        if (data.query) {
            datas.search = data.query
        }
        if (data.selectedTab) {
            datas.status = data.selectedTab
        }
        if (data.components) {
            datas.only_components = data.components
        }
    }
    return ApiService.get(`/user/user_product/get_orphan_products/`, datas)
}
export const createApiRecord = data => ApiService.post('/product/product/', data)
export const getApiRecord = id => ApiService.get(`/product/product/${id}/`)
export const updateApiRecord = (id, data) => ApiService.put(`/product/product/${id}/`, data)
export const deleteApiRecord = id => ApiService.delete(`/product/product/${id}/`)
export const assignCompanyApi = data => ApiService.post('/user/assign_company_orphan', data)
export const getInvoiceDocApi = data => ApiService.get(`/product/invoice_document/`, data)
export const verifyInvoiceApi = data => ApiService.post(`/product/verify_product`, data)
export const attachInvoiceApi = data => ApiService.filePost(`/product/add_document_to_user_product`, data)
