import React from 'react'
import ReactApexChart from 'react-apexcharts'

const ChartThree = props => {
    const { series, labels } = props

    const options = {
        chart: {
            width: 600,
            type: 'pie',
        },
        labels: labels,
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    }

    return (
        <div className="sm:px-7.5 col-span-12 rounded-sm border border-stroke bg-white px-5 pb-5 pt-7.5 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-6">
            <div className="justify-between gap-4 mb-3 sm:flex">
                <div>
                    <h5 className="text-xl font-semibold text-black dark:text-white">Products Demography</h5>
                </div>
            </div>

            <div className="mb-2">
                <div id="chartThree" className="flex justify-center mx-auto">
                    <ReactApexChart options={options} series={series} type="pie" width={600} />
                </div>
            </div>
        </div>
    )
}

export default ChartThree
