import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, FileUpload, TextInput } from 'components/Form'
import GooglePlacesAutocomplete, { geocodeByLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete'
// import { getApiRecord } from 'services/UserService'
const { REACT_APP_GOOGLE_PLACES_API } = process.env

const resetData = {
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    contact_number: '',
    profile_photo: '',
}

const Profile = () => {
    const { id } = useParams()
    const [data, setData] = useState(resetData)
    const [value, setValue] = useState('')
    const [place, setPlace] = useState('')

    useEffect(() => {
        if (id) {
            getProfileData(id)
        }
        // eslint-disable-next-line
    }, [])

    const getProfileData = id => {
        // getApiRecord(id)
        //     .then(responce => setData(responce.data))
        //     .catch(error => console.log('Error: ', error))
    }

    const handleChange = e => {
        const { name, value } = e.target
        handleSetData(name, value)
    }

    const handleSetData = (name, value) => {
        setData(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }

    const getPlace = async location => {
        let result = null
        if (location?.value?.place_id) {
            const place = await geocodeByPlaceId(location.value.place_id)
            if (place.length) {
                result = place[0]
            }
        }

        let street_address_2 = ''
        let suburb = ''
        let state = ''
        let stateAlias = ''
        let postcode = ''
        let country = ''
        let countryAlias = ''

        if (result?.address_components) {
            result.address_components
                .slice()
                .reverse()
                .forEach(component => {
                    if (component.types.includes('subpremise')) {
                        street_address_2 = component.long_name
                    }
                    if (component.types.includes('locality')) {
                        suburb = component.long_name
                    }
                    if (component.types.includes('administrative_area_level_1')) {
                        state = component.long_name
                        stateAlias = component.short_name
                    }
                    if (component.types.includes('postal_code')) {
                        postcode = component.long_name
                    }
                    if (component.types.includes('country')) {
                        country = component.long_name
                        countryAlias = component.short_name
                    }
                })
        }

        if (!postcode) {
            const places = await geocodeByLatLng({
                lat: result.geometry.location.lat(),
                lng: result.geometry.location.lng(),
            })
            if (places.length) {
                places.some(place => {
                    for (let i = 0; i < place.address_components.length; i++) {
                        const component = place.address_components[i]
                        if (component.types.includes('postal_code')) {
                            postcode = component.short_name
                            return true
                        }
                    }
                    return false
                })
            }
        }

        return {
            street_address_1: location.label,
            street_address_2,
            suburb,
            state,
            stateAlias,
            postcode,
            country,
            countryAlias,
            lat: result?.geometry?.location?.lat() ?? 0,
            lng: result?.geometry?.location?.lng() ?? 0,
        }
    }

    const handleSubmit = () => {
        handleAddress(place)
    }

    const handleAddress = place => {
        // if (place) {
        //     createCountryApi({
        //         country_alias: place.countryAlias,
        //         name: place.country,
        //     })
        //         .then(responce => {
        //             createStateApi({
        //                 state_alias: place.stateAlias,
        //                 post_code: place.postcode,
        //                 name: place.state,
        //                 country: responce.data.id,
        //             })
        //                 .then(responce => {
        //                     createApiRecord({
        //                         street_address_1: place.street_address_1,
        //                         street_address_2: place.street_address_2,
        //                         suburb: place.suburb,
        //                         address_type: 'billing',
        //                         is_active: true,
        //                         lat: place.lat,
        //                         lng: place.lng,
        //                         user: id,
        //                         state: responce.data.id,
        //                         country: responce.data.country,
        //                     })
        //                         .then(responce => {})
        //                         .catch(error => console.log('Error: ', error))
        //                 })
        //                 .catch(error => console.log('Error: ', error))
        //         })
        //         .catch(error => console.log('Error:', error))
        // }
    }

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Profile</h2>
            </div>
            <div className="flex flex-col items-center">
                <div className="w-full bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                    <div className="px-5 py-3 border-b border-stroke dark:border-strokedark">
                        <h3 className="font-medium text-black dark:text-white">Edit Profile</h3>
                    </div>
                    <div className="flex flex-col gap-3 p-6">
                        <div className="grid grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-4">
                            <TextInput
                                type="text"
                                className="md:col-span-2"
                                label="First Name"
                                name="first_name"
                                value={data.first_name}
                                onChange={handleChange}
                            />
                            <TextInput
                                type="text"
                                className="md:col-span-2"
                                label="Last Name"
                                name="last_name"
                                value={data.last_name}
                                onChange={handleChange}
                            />
                            <TextInput
                                type="text"
                                className="md:col-span-2"
                                label="Username"
                                name="username"
                                value={data.username}
                                onChange={handleChange}
                            />
                            <TextInput
                                type="email"
                                className="md:col-span-2"
                                label="Email"
                                name="email"
                                value={data.email}
                                onChange={handleChange}
                            />
                            <TextInput
                                type="text"
                                className="md:col-span-2"
                                label="Contact Number"
                                name="contact_number"
                                value={data.contact_number}
                                onChange={handleChange}
                            />
                            <FileUpload
                                label="Profile Image"
                                className="md:col-span-2"
                                name="profile_photo"
                                onChange={e => handleSetData('profile_photo', e.target.files[0])}
                            />
                            <div className="md:col-span-2">
                                <label htmlFor={id} className={`block mb-1 font-medium text-black dark:text-white`}>
                                    {'Address'}
                                </label>
                                <GooglePlacesAutocomplete
                                    apiKey={REACT_APP_GOOGLE_PLACES_API}
                                    selectProps={{
                                        value: value,
                                        openMenuOnFocus: false,
                                        openMenuOnClick: false,
                                        isClearable: true,
                                        // components: {
                                        //     DropdownIndicator: () => (
                                        //         <div className="select-dropdown-indicator">
                                        //             <HiOutlineSearch className="text-lg"/>
                                        //         </div>
                                        //     ),
                                        // },
                                        onChange: async search => {
                                            setValue(search)
                                            if (search) {
                                                const place = await getPlace(search)
                                                setPlace(place)
                                            }
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-end gap-4 mt-3">
                            {/* <Button
                                className="h-10 w-18 text-primary border-primary dark:text-secondary dark:border-secondary"
                                onClick={handleDiscard}
                            >
                                Discard
                            </Button> */}
                            <Button
                                onClick={handleSubmit}
                                className="flex items-center justify-center px-6 py-2 text-white transition border rounded-lg cursor-pointer hover:bg-opacity-90 border-primary bg-primary dark:border-secondary dark:bg-secondary"
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile
