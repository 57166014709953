import Header from 'components/layout/Header'
import Sidebar from 'components/layout/Sidebar'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { getCategoryData, getCompanyData } from 'store/data/dataSlice'
import useAuth from 'utils/hooks/useAuth'

export default function AppLayout() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const navigate = useNavigate()
    const { authenticated } = useAuth()
    const location = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (!authenticated) {
            navigate('/login')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    useEffect(() => {
        if (authenticated) {
            dispatch(getCompanyData())
            dispatch(getCategoryData())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, authenticated])

    return (
        <div className="dark:bg-boxdark2 dark:text-bodydark">
            <div className="flex h-screen overflow-hidden">
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
                    <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    <main>
                        <div className="p-4 mx-auto max-w-screen-2xl md:p-5 2xl:p-8">
                            <Outlet />
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}
