import React from 'react'
// import { useTranslation } from 'react-i18next'
import { Button, Checkbox, Select, TextInput } from 'components/Form'
import { BiSearchAlt } from 'react-icons/bi'
import { AiFillCaretDown, AiFillCaretUp, AiOutlinePlus } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { CompanyTypeOptions, ComponentFilterOptions, filterOptions, lengthOption } from 'utils/options'
import { useSelector } from 'react-redux'
import Tooltip from './Tooltip'

const interpolate = require('color-interpolate')
let colormap = interpolate([
    '#ff0000',
    '#fb6436',
    '#ef8d57',
    '#deab6f',
    '#c8c07d',
    '#aecf80',
    '#91d87a',
    '#71db68',
    '#4bd949',
    '#00d100',
])

const defoltclassName = 'py-2 px-2 md:px-4 2xl:px-7'

const Table = ({
    columns,
    loading,
    className,
    datas,
    add_new,
    link,
    link_name,
    search_show = true,
    page_size_show = true,
    filter = true,
    companyFilter = false,
    checkboxFilter = false,
    componentFilter = false,
    companyTypeFilter = false,
    select = false,
    buttons = [],
    name = 'datas',
    onChange,
    onClick,
    pagingData,
    ids,
    setIds,
    grid = 'sm:flex-row sm:justify-between',
    subGrid = 'sm:flex-row sm:justify-between',
    children,
}) => {
    // const { t } = useTranslation()

    const { pageSize, pageIndex, total, sort, query, selectedTab, company, checkBox, components, companyType } = pagingData
    const { companyData } = useSelector(state => state.listData)

    var totalPage = Math.ceil(total / pageSize)
    var pages = []
    var loadingPages = ['Previous', '...', 'Next']
    const from = pageSize * (pageIndex - 1) + 1
    const to = total > pageSize * pageIndex ? pageSize * pageIndex : total

    for (let i = 1; i <= totalPage; i++) {
        if (i === 1) {
            pages.push('Previous')
            pages.push(i)
        } else if (i === totalPage) {
            pages.push(i)
            pages.push('Next')
        } else {
            pages.push(i)
        }
    }

    const ColumnTd = ({ row, column }) => {
        return (
            <td
                style={{ backgroundColor: column?.bg ? colormap(row[column?.bg] * 100) : '' }}
                className={`border-b border-stroke dark:border-strokedark items-center text-sm text-black dark:text-white ${defoltclassName} ${column.className}`}
            >
                {column.renderCell ? column.renderCell(row) : row[column.field]}
            </td>
        )
    }
    const gridClass = `flex flex-col items-center gap-3 ${grid}`
    const subGridClass = `flex flex-col items-center gap-3 ${subGrid}`

    const onPaginationChange = page => {
        const newTableData = { ...pagingData }
        newTableData.pageIndex = page
        onChange(newTableData)
    }

    const onSelectChange = val => {
        const newTableData = { ...pagingData }
        newTableData.pageSize = val
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    const onSort = sort => {
        const newTableData = { ...pagingData }
        newTableData.sort = sort
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    const onSearch = val => {
        const newTableData = { ...pagingData }
        newTableData.query = val
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    const onTabChange = value => {
        const newTableData = { ...pagingData }
        newTableData.selectedTab = value
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    const onCompanyChange = value => {
        const newTableData = { ...pagingData }
        newTableData.company = value
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    const onCheckboxChange = value => {
        const newTableData = { ...pagingData }
        newTableData.checkBox = value
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    const onComponentFilterChange = value => {
        const newTableData = { ...pagingData }
        newTableData.components = value
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    const onCompanyTypeChange = value => {
        const newTableData = { ...pagingData }
        newTableData.companyType = value
        newTableData.pageIndex = 1
        onChange(newTableData)
    }

    return (
        <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className={`px-3 md:px-4 xl:px-6 pt-3 md:pt-4 xl:pt-6 ${gridClass}`}>
                <div className={subGridClass}></div>
                <div className={subGridClass}>
                    {componentFilter && (
                        <Select
                            id="length"
                            name="length"
                            value={components}
                            className="min-w-28"
                            options={ComponentFilterOptions}
                            onChange={e => onComponentFilterChange?.(e.target.value)}
                        />
                    )}
                    {companyTypeFilter && (
                        <Tooltip title={'Company Type'}>
                            <Select
                                id="length"
                                name="length"
                                value={companyType}
                                className="min-w-28"
                                options={CompanyTypeOptions}
                                onChange={e => onCompanyTypeChange?.(e.target.value)}
                            />
                        </Tooltip>
                    )}
                </div>
            </div>
            <div className={`p-3 md:p-4 xl:p-6 ${gridClass}`}>
                {page_size_show ? (
                    <div className={subGridClass}>
                        <div className="flex flex-row items-center gap-2">
                            <p className="text-black dark:text-white">{'Show'}</p>
                            <Select
                                id="length"
                                name="length"
                                value={pageSize ?? 10}
                                className="w-17"
                                options={lengthOption}
                                onChange={e => onSelectChange?.(e.target.value)}
                            />
                            <p className="text-black dark:text-white">{'Entries'}</p>
                        </div>
                        {children}
                        {checkboxFilter && (
                            <div className="flex gap-2">
                                <Checkbox
                                    id={'register'}
                                    name={'register'}
                                    checked={checkBox?.includes('register')}
                                    onChange={e => {
                                        const { name } = e.target
                                        let arr = [...checkBox]
                                        if (arr.includes(name)) {
                                            if (arr.length > 1) {
                                                arr = arr.filter(item => item !== name)
                                            }
                                        } else {
                                            arr.push(name)
                                        }
                                        onCheckboxChange(arr)
                                    }}
                                    label={'Register'}
                                />
                                <Checkbox
                                    id={'not_register'}
                                    name={'not_register'}
                                    checked={checkBox?.includes('not_register')}
                                    onChange={e => {
                                        const { name } = e.target
                                        let arr = [...checkBox]
                                        if (arr.includes(name)) {
                                            if (arr.length > 1) {
                                                arr = arr.filter(item => item !== name)
                                            }
                                        } else {
                                            arr.push(name)
                                        }
                                        onCheckboxChange(arr)
                                    }}
                                    label={'Not Register'}
                                />
                            </div>
                        )}
                    </div>
                ) : null}
                <div className={subGridClass}>
                    {buttons.length > 0 && (
                        <div className="flex flex-row items-center gap-3">
                            {buttons.map((text, index) => (
                                <Button
                                    key={index}
                                    as="a"
                                    // href={url}
                                    data={{ download: text }}
                                    onClick={onClick}
                                    className="text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                >
                                    {text === 'Add New' ? <AiOutlinePlus size={20} /> : null} {text}
                                </Button>
                            ))}
                        </div>
                    )}
                    {filter && (
                        <Select
                            id="length"
                            name="length"
                            value={selectedTab}
                            className="min-w-28"
                            options={filterOptions}
                            onChange={e => onTabChange?.(e.target.value)}
                        />
                    )}
                    {companyFilter && (
                        <Select
                            id="length"
                            name="length"
                            placeholder="All"
                            value={company}
                            className="min-w-28"
                            options={companyData}
                            onChange={e => onCompanyChange?.(e.target.value)}
                        />
                    )}
                    {search_show && (
                        <TextInput
                            id="search"
                            name="search"
                            value={query ?? ''}
                            onChange={e => onSearch?.(e.target.value)}
                            placeholder={'Search'}
                            icon={<BiSearchAlt />}
                            className="w-full sm:w-auto"
                        />
                    )}
                    {(link || add_new) && (
                        <div className="flex flex-row items-center w-full gap-3 sm:w-auto">
                            {link && (
                                <Button
                                    href={link}
                                    as="button"
                                    className="text-primary border-primary dark:text-secondary dark:border-secondary"
                                >
                                    {link_name}
                                </Button>
                            )}
                            {add_new && (
                                <Link to={add_new}>
                                    <Button
                                        as="button"
                                        className="text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                    >
                                        <AiOutlinePlus size={20} /> {'Add New'}
                                    </Button>
                                </Link>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className={`max-w-full overflow-x-auto ${className}`}>
                <table className="w-full table-auto">
                    <thead>
                        <tr className={`bg-primary dark:bg-secondary bg-opacity-90 text-left`}>
                            {select && (
                                <th className="px-3 py-2 font-medium text-white md:px-3 2xl:px-3">
                                    <Checkbox
                                        id={'select_all'}
                                        name={'select_all'}
                                        checked={datas.length !== 0 && datas.length === ids.length}
                                        onChange={() => setIds(datas.length !== ids.length ? datas.map(row => row.id) : [])}
                                    />
                                </th>
                            )}
                            {columns.map((row, index) => (
                                <th key={index} className={`font-medium text-white ${defoltclassName} ${row.className}`}>
                                    {row.orderable ? (
                                        <div
                                            className="flex items-center justify-between gap-1 cursor-pointer"
                                            onClick={() => {
                                                if (sort[0].dir !== 'desc') {
                                                    const data = [
                                                        {
                                                            column: row.field,
                                                            dir:
                                                                sort[0].column === row.field && sort[0].dir === 'asc'
                                                                    ? 'desc'
                                                                    : 'asc',
                                                        },
                                                    ]
                                                    onSort?.(data)
                                                } else {
                                                    onSort?.([{ column: '', dir: '' }])
                                                }
                                            }}
                                        >
                                            <p>{row.header}</p>
                                            <div className="inline-flex flex-col space-y-[2px]">
                                                {(sort[0].column !== row.field || sort[0].dir !== 'asc') && (
                                                    <AiFillCaretUp size={14} style={{ margin: '-1px' }} />
                                                )}
                                                {(sort[0].column !== row.field || sort[0].dir !== 'desc') && (
                                                    <AiFillCaretDown size={14} style={{ margin: '-1px' }} />
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        row.header
                                    )}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="">
                        {datas.map((row, index) => (
                            <tr key={index} className="hover:bg-whiten dark:hover:bg-boxdark2">
                                {select && (
                                    <td className="items-center px-3 py-2 text-sm text-black border-b border-stroke dark:border-strokedark dark:text-white md:px-3 2xl:px-3">
                                        <Checkbox
                                            id={'select_' + row.id}
                                            name={'select_' + row.id}
                                            checked={ids.includes(row.id)}
                                            onChange={() => {
                                                var selectIds = [...ids]
                                                if (!selectIds.includes(row.id)) {
                                                    selectIds.push(row.id)
                                                } else {
                                                    selectIds = selectIds.filter(g => g !== row.id)
                                                }
                                                setIds(selectIds)
                                            }}
                                        />
                                    </td>
                                )}
                                {columns.map((column, key) => (
                                    <ColumnTd key={key} row={row} column={column} />
                                ))}
                            </tr>
                        ))}
                        {datas.length === 0 && (
                            <tr>
                                <td
                                    className={`border-b border-stroke dark:border-strokedark items-center text-center text-primary dark:text-white ${defoltclassName}`}
                                    colSpan="100%"
                                >
                                    {loading ? 'Loading...' : 'Data not found'}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {(totalPage > 1 || loading) && (
                <div className="flex flex-col gap-3 p-3 sm:p-4 xl:p-6 sm:flex-row sm:items-center sm:justify-between">
                    <p className="text-sm text-black dark:text-white">
                        {`Showing ${from} to 
                        ${to} of ${total} entries`}
                    </p>
                    <nav>
                        <ul className="flex flex-wrap items-center w-full">
                            {(loading ? loadingPages : pages)?.map((link, key) => (
                                <li key={key}>
                                    {link !== pageIndex ? (
                                        <div
                                            onClick={() => {
                                                if (typeof link === 'string') {
                                                    if (link === 'Previous') {
                                                        if (pageIndex !== 1) {
                                                            onPaginationChange(pageIndex - 1)
                                                        }
                                                    } else if (link === 'Next') {
                                                        if (pageIndex !== totalPage) {
                                                            onPaginationChange(pageIndex + 1)
                                                        }
                                                    }
                                                } else {
                                                    onPaginationChange(link)
                                                }
                                            }}
                                            className={`flex items-center justify-center border py-[5px] px-4 font-medium text-sm  
                                                ${key !== 0 && link !== pageIndex && 'border-l-transparent'}
                                                ${
                                                    link === pageIndex
                                                        ? 'border-primary bg-gray text-primary dark:border-secondary dark:bg-graydark'
                                                        : 'text-black dark:text-white border-stroke hover:border-primary hover:bg-gray hover:text-primary dark:hover:text-secondary dark:border-strokedark dark:hover:border-secondary dark:hover:bg-graydark'
                                                }
                                                ${key === 0 && 'rounded-l-md'}
                                                ${pages.length === key + 1 && 'rounded-r-md'}
                                            `}
                                        >
                                            {link}
                                        </div>
                                    ) : (
                                        <div
                                            className={`flex items-center justify-center border py-[5px] px-4 font-medium text-sm 
                                                ${key !== 0 && link !== pageIndex && 'border-l-transparent'}
                                                ${key === 0 && 'border-r-transparent'}
                                                ${
                                                    link === pageIndex
                                                        ? 'border-primary bg-gray text-primary dark:border-secondary dark:bg-graydark'
                                                        : 'text-black dark:text-white border-stroke dark:border-strokedark'
                                                }
                                                ${key === 0 && 'rounded-l-md'}
                                                ${pages.length === key + 1 && 'rounded-r-md'}
                                            `}
                                        >
                                            {link}
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            )}
        </div>
    )
}
export default Table
