export const yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
]

export const yesNoOptionsNum = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
]

export const filterOptions = [
    { label: 'All', value: '' },
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
]

export const lengthOption = [
    {
        id: 10,
        label: 10,
    },
    {
        id: 25,
        label: 25,
    },
    {
        id: 50,
        label: 50,
    },
    {
        id: 100,
        label: 100,
    },
]

export const ProductFromOptions = [
    { label: 'By Individual', value: 'individual' },
    { label: 'By Business', value: 'business' },
    { label: 'By Admin', value: 'admin' },
    { label: 'By User', value: 'user' },
    { label: 'By Invite', value: 'invite' },
    { label: 'Other', value: 'other' },
]

export const ComponentFilterOptions = [
    { label: 'Product', value: false },
    { label: 'Component', value: true },
]

export const CompanyTypeOptions = [
    { label: 'All', value: 'all' },
    { label: 'Register', value: 'permanent' },
    { label: 'Not Register', value: 'temporary' },
]
