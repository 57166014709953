import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getApiData } from 'services/JourneyService'

export const getData = createAsyncThunk('journey/data/getData', async data => {
    const response = await getApiData(data)
    return response.data
})

const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    selectedTab: '',
    sort: [{ column: '', dir: '' }],
}

const dataSlice = createSlice({
    name: 'journey/data',
    initialState: {
        loading: true,
        data: [],
        tableData: initialTableData,
    },
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload
        },
        setTableData: (state, { payload }) => {
            state.tableData = payload
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getData.pending, state => {
                state.loading = true
                state.tableData.total = 0
            })
            .addCase(getData.fulfilled, (state, { payload }) => {
                state.loading = false
                state.data = payload?.results
                state.tableData.total = payload?.count
            })
            .addCase(getData.rejected, state => {
                state.loading = false
                state.data = []
            })
    },
})

export const { setData, setTableData } = dataSlice.actions

export default dataSlice.reducer
