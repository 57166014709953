import ApiService from './ApiService'

export const getApiData = data => {
    var datas
    if (data) {
        datas = {
            page: data.pageIndex,
            page_size: data.pageSize,
            product: data?.product_id,
        }
        data.sort?.map(sort => {
            if (!sort.dir) return sort
            datas.ordering = sort.dir === 'desc' ? `-${sort.column}` : `${sort.column}`
            return sort
        })
        if (data.query) {
            datas.search = data.query
        }
        if (data.selectedTab) {
            datas.status = data.selectedTab
        }
    }
    return ApiService.get('/company/company_user_products/', datas)
}
export const createApiRecord = data => ApiService.post('/company/company_user_products/', data)
export const getApiRecord = id => ApiService.get(`/company/company_user_products/${id}/`)
export const updateApiRecord = (id, data) => ApiService.put(`/company/company_user_products/${id}/`, data)
export const deleteApiRecord = id => ApiService.delete(`/company/company_user_products/${id}/`)
export const createLableApiRecord = data => ApiService.post('/user/user_product_label/', data)
