import React, { useEffect, useMemo, useState } from 'react'
import { injectReducer } from 'store'
import reducer from './store'
import { useDispatch, useSelector } from 'react-redux'
import { getData, setTableData } from './store/dataSlice'
import { MdOutlineAssignmentTurnedIn } from 'react-icons/md'
import Tooltip from 'components/Ui/Tooltip'
import { Modal, Table } from 'components/Ui'
import { Button, Select } from 'components/Form'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { assignCompanyApi, getInvoiceDocApi } from 'services/ProductService'
import { Success } from 'utils/alerts'
injectReducer('unassignedProduct', reducer)

const { REACT_APP_URL } = process.env

const validationSchema = Yup.object().shape({
    company: Yup.string().required('please select company'),
})

export default function Products() {
    const dispatch = useDispatch()
    const { companyData } = useSelector(state => state.listData)
    const { data, tableData } = useSelector(state => state.unassignedProduct.data)
    const [open, setOpen] = useState(false)
    const [userProductId, setUserProductId] = useState(0)
    const [invoiceDoc, setInvoiceDoc] = useState([])
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab])

    const fetchData = () => dispatch(getData({ ...tableData }))

    const handleAssignCompany = id => {
        getInvoiceDocApi({ user_product_id: id })
            .then(response => setInvoiceDoc(response.data))
            .catch(error => console.log('Error: ', error))
        setOpen(true)
        setUserProductId(id)
    }

    const onAssign = (data, setSubmitting) => {
        setSubmitting(true)
        assignCompanyApi(data)
            .then(() => {
                setSubmitting(false)
                Success('Assign Successfully!')
                setInvoiceDoc([])
                setOpen(false)
                fetchData()
            })
            .catch(error => {
                setSubmitting(false)
                console.log('Error: ', error)
            })
    }

    const columns = useMemo(
        () => [
            {
                field: 'user',
                header: 'Customer',
                className: 'min-w-[150px]',
                renderCell: row => `${row?.user?.first_name} ${row?.user?.last_name}`,
            },
            {
                field: 'product_label',
                header: 'Product Name',
                className: 'min-w-[150px]',
            },
            {
                field: 'total_price',
                header: 'Total Price',
                className: `min-w-[150px]`,
                bg: 'total_price_confidence',
            },
            {
                field: 'invoice_number',
                header: 'Invoice Number',
                className: 'min-w-[150px]',
                bg: 'invoice_confidence',
            },
            {
                field: 'product_serial_number',
                header: 'Serial Number',
                className: 'min-w-[150px]',
            },
            // {
            //     field: 'image',
            //     header: 'Image',
            //     className: 'min-w-[150px]',
            //     renderCell: row => {
            //         return (
            //             <div className='flex items-center justify-center w-20 h-20 overflow-hidden'>
            //                 <a href={row?.product?.image} target='_blank' rel="noreferrer">
            //                     <img src={row?.product?.image} alt="error" />
            //                 </a>
            //             </div>
            //         )
            //     },
            // },
            {
                header: 'Action',
                className: 'px-4',
                renderCell: row => {
                    return (
                        <div className="flex items-center gap-3">
                            <Tooltip title={'Assign Company'}>
                                <MdOutlineAssignmentTurnedIn size={28} onClick={() => handleAssignCompany(row?.id)} />
                            </Tooltip>
                        </div>
                    )
                },
            },
        ],
        // eslint-disable-next-line
        [tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab]
    )

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Unassigned Products</h2>
            </div>
            <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                <Table
                    filter={false}
                    search_show={false}
                    datas={data}
                    columns={columns}
                    pagingData={tableData}
                    onChange={e => dispatch(setTableData(e))}
                />
            </div>
            <Modal
                show={open}
                onClose={() => {
                    setOpen(false)
                    setInvoiceDoc([])
                }}
            >
                <div className="p-6">
                    <h2 className="mb-4 font-semibold text-black text-title-md2 dark:text-white">Assign Company</h2>
                    {invoiceDoc[0]?.image ? (
                        <div className="flex justify-center w-full">
                            <div className="flex items-center justify-center w-40 h-40 mb-4 overflow-hidden">
                                <a href={`${REACT_APP_URL}${invoiceDoc[0]?.image}`} target="_blank" rel="noreferrer">
                                    <img src={`${REACT_APP_URL}${invoiceDoc[0]?.image}`} alt="error" />
                                </a>
                            </div>
                        </div>
                    ) : null}
                    <Formik
                        initialValues={{
                            company: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            onAssign({ ...values, user_product: userProductId }, setSubmitting)
                        }}
                    >
                        {({ values, touched, errors, isSubmitting }) => (
                            <Form className="flex flex-col gap-3">
                                <Field name="company">
                                    {({ field, form }) => (
                                        <Select
                                            options={companyData?.filter(item => !item?.is_temporary && item?.registered_with_us)}
                                            label="Company"
                                            size="sm"
                                            placeholder="Select"
                                            className="md:col-span-2"
                                            name="company"
                                            error={errors.company && touched.company}
                                            message={errors.company}
                                            value={values?.company}
                                            onChange={e => form.setFieldValue(field.name, e.target.value)}
                                        />
                                    )}
                                </Field>
                                <Button
                                    className="w-full mt-3 text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                    disabled={isSubmitting}
                                    type="submit"
                                >
                                    {isSubmitting ? 'Assigning...' : 'Assign'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </>
    )
}
