import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { injectReducer } from 'store'
import reducer from './store'
import { useDispatch, useSelector } from 'react-redux'
import { getData, setTableData } from './store/dataSlice'
import { HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi'
import Tooltip from 'components/Ui/Tooltip'
import { ConfirmationModal, Table } from 'components/Ui'
import { deleteApiRecord, updateApiRecord } from 'services/CategoryService'
import { Success } from 'utils/alerts'
injectReducer('category', reducer)

export default function Categories() {
    const dispatch = useDispatch()
    const { data, tableData } = useSelector(state => state.category.data)

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab])

    const fetchData = () => dispatch(getData({ ...tableData }))

    const handleDelete = id => {
        deleteApiRecord(id)
            .then(() => {
                Success('Deleted Successfully!')
                fetchData()
            })
            .catch(error => console.log('Error: ', error))
    }

    const handleStatus = (id, data) => {
        delete data.image
        updateApiRecord(id, data)
            .then(() => {
                Success('Status Updated Successfully!')
                fetchData()
            })
            .catch(error => console.log('Error: ', error))
    }

    const columns = useMemo(
        () => [
            {
                field: 'name',
                header: 'Name',
                className: 'min-w-[150px]',
            },
            {
                field: 'sub_name',
                header: 'Description',
                className: 'min-w-[150px]',
            },
            {
                field: 'image',
                header: 'Image',
                className: 'min-w-[150px]',
                renderCell: row => {
                    return (
                        <div className="flex items-center justify-center w-20 h-20">
                            <a href={row?.image} target="_blank" rel="noreferrer">
                                <img src={row?.image ?? '/images/ypp-logo.png'} alt="error" />
                            </a>
                        </div>
                    )
                },
            },
            {
                header: 'Status',
                renderCell: row => {
                    return (
                        <ConfirmationModal
                            onConfirm={() => {
                                handleStatus(row.id, { ...row, is_active: !row.is_active })
                            }}
                        >
                            <div>
                                <div
                                    className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${row.is_active ? 'text-success bg-success' : 'text-danger bg-danger'}`}
                                >
                                    {row.is_active ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                        </ConfirmationModal>
                    )
                },
            },
            {
                header: 'Action',
                className: 'px-4',
                renderCell: row => {
                    return (
                        <div className="flex items-center gap-3">
                            <Tooltip title={'Edit'}>
                                <Link to={`/manage-product/categories/${row.id}/edit`} className="hover:text-primary">
                                    <HiOutlinePencil size={20} />
                                </Link>
                            </Tooltip>
                            <Tooltip title={'Delete'}>
                                <ConfirmationModal
                                    message={'Are you sure you want to delete this record!'}
                                    onConfirm={() => {
                                        handleDelete(row.id)
                                    }}
                                >
                                    <span className="hover:text-danger">
                                        <HiOutlineTrash size={20} />
                                    </span>
                                </ConfirmationModal>
                            </Tooltip>
                        </div>
                    )
                },
            },
        ],
        // eslint-disable-next-line
        [tableData.sort, tableData.query, tableData.pageIndex, tableData.pageSize, tableData.selectedTab]
    )

    return (
        <>
            <div className="flex flex-col gap-1 mb-3 sm:flex-row sm:items-center sm:justify-between">
                <h2 className="font-semibold text-black text-title-md2 dark:text-white">Categories</h2>
            </div>
            <div className="bg-white border rounded-sm border-stroke shadow-default dark:border-strokedark dark:bg-boxdark">
                <Table
                    filter={false}
                    // search_show={false}
                    datas={data}
                    columns={columns}
                    add_new={'/manage-product/categories/create'}
                    pagingData={tableData}
                    onChange={e => dispatch(setTableData(e))}
                />
            </div>
        </>
    )
}
