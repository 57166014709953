import { forwardRef, useRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export default forwardRef(function TextEditor({ id = '', label = '', className = '', error = '', message = '', ...props }, ref) {
    const input = useRef()

    return (
        <div className={`${className} w-full min-h-[200px]`}>
            {label && (
                <label htmlFor={id} className={`block mb-1 font-medium ${error ? 'text-danger' : 'text-black dark:text-white'}`}>
                    {label}
                </label>
            )}
            <div>
                <ReactQuill theme="snow" className="h-[120px]" {...props} ref={ref || input} />
            </div>
            {error && <p className="text-sm mt-11 text-danger">{message}</p>}
        </div>
    )
})
