import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getApiData, getApiRecord } from 'services/ProductService'

export const getData = createAsyncThunk('product/data/getData', async data => {
    const response = await getApiData(data)
    return response.data
})

export const getRecord = createAsyncThunk('product/data/getRecord', async id => {
    const response = await getApiRecord(id)
    const data = {
        ...response.data,
        // company_id: response.data?.company,
        // category_id: response.data?.category,
        // sub_category_id: response.data?.sub_category ?? '',
    }
    return data
})

const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    selectedTab: '',
    sort: [{ column: '', dir: '' }],
    company: '',
    components: false,
    companyType: 'all',
}

const dataSlice = createSlice({
    name: 'product/data',
    initialState: {
        loading: true,
        data: [],
        tableData: initialTableData,
    },
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload
        },
        setTableData: (state, { payload }) => {
            state.tableData = payload
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getData.pending, state => {
                state.loading = true
                state.tableData.total = 0
            })
            .addCase(getData.fulfilled, (state, { payload }) => {
                state.loading = false
                state.data = payload.results
                state.tableData.total = payload.count
            })
            .addCase(getData.rejected, state => {
                state.loading = false
                state.data = []
            })
            .addCase(getRecord.fulfilled, (state, { payload }) => {
                state.loading = false
                state.record = payload
            })
            .addCase(getRecord.pending, state => {
                state.loading = true
                state.record = null
            })
            .addCase(getRecord.rejected, state => {
                state.loading = false
                state.record = null
            })
    },
})

export const { setData, setTableData } = dataSlice.actions

export default dataSlice.reducer
