import React from 'react'
import ReactApexChart from 'react-apexcharts'

const colors = ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800']

const ChartTwo = props => {
    const { categories, data } = props

    const options = {
        colors: colors,
        chart: {
            height: 350,
            type: 'bar',
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            },
        },
        responsive: [
            {
                breakpoint: 1536,
                options: {
                    plotOptions: {
                        bar: {
                            borderRadius: 0,
                            columnWidth: '25%',
                        },
                    },
                },
            },
        ],

        xaxis: {
            categories: categories,
        },

        labels: {
            style: {
                colors: colors,
                fontSize: '12px',
            },
        },
    }

    const state = {
        series: [
            {
                name: 'Count',
                data: data,
            },
        ],
    }

    return (
        <div className="col-span-12 rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-12">
            <div className="justify-between gap-4 mb-4 sm:flex">
                <div>
                    <h4 className="text-xl font-semibold text-black dark:text-white">Products by Month</h4>
                </div>
            </div>
            <div>
                <div id="chartTwo" className="-ml-5 -mb-9">
                    <ReactApexChart options={options} series={state.series} type="bar" height={350} />
                </div>
            </div>
        </div>
    )
}

export default ChartTwo
